import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Async thunk action for login
export const loginAdmin = createAsyncThunk(
  'authentication/loginAdmin',
  async (credentials, { rejectWithValue }) => {
    try {
      // Make API call to login endpoint
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/login_admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)

      })
      // Handle non-2xx responses
      if (!response.ok) {
        const error = await response.json()
        return rejectWithValue(error.message)
      }

      const data = await response.json()
      // console.log(data, "Login Response-----------------")


      // Return Response data
      return  data
    } catch (error) {
      return rejectWithValue('Internal Server Error')
    }
  }
)

export const sentOtp = createAsyncThunk(
  'authentication/sentOtp',
  async (email, { rejectWithValue }) => {
    try {
      // Make API call to send OTP endpoint
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/request-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email }) // Send email in the request body
      })

      // Handle non-2xx responses
      if (!response.ok) {
        const error = await response.json()
        return rejectWithValue(error.message)
      }

      const data = await response.json()

      // Return Response data
      return data
    } catch (error) {
      return rejectWithValue('Internal Server Error')
    }
  }
)

export const verifyOtp = createAsyncThunk(
  'authentication/verifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      // Make API call to verify OTP endpoint
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp:Number(otp) }) // Send email and OTP in the request body
      })

      // Handle non-2xx responses
      if (!response.ok) {
        const error = await response.json()
        return rejectWithValue(error.message)
      }

      const data = await response.json()

      // Return response data
      return data
    } catch (error) {
      return rejectWithValue('Internal Server Error')
    }
  }
)

// Async thunk action for logout
export const handleLogout = createAsyncThunk(
  'authentication/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
     
      localStorage.removeItem('token')
      return 'Logout successful'
    } catch (error) {
      return rejectWithValue('Internal Server Error')
    }
  }
)


const initialUser = () => {
  const token = localStorage.getItem('token')
  return token ? { token } : {}
}

const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    error: null, // Error state for login/logout API
    loading: false, // Loading state for login/logout API
    otpSent: false, // Add state to track OTP sent status
    otpVerified: false // Add state to track OTP verification status
  },
  reducers: {},
  extraReducers: {
    [loginAdmin.pending]: state => {
      state.loading = true
      state.error = null
    },
    [loginAdmin.fulfilled]: (state, action) => {
      state.loading = false
      state.userData = { token: action.payload }
      state.error = null
      if (action.payload.status === 200) {
        localStorage.setItem('token', action.payload?.token)
      }
    },
    [loginAdmin.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [handleLogout.pending]: state => {
      state.loading = true
      state.error = null
    },
    [handleLogout.fulfilled]: (state, action) => {
      state.loading = false
      state.userData = {}
      state.error = action.payload
    },
    [handleLogout.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [sentOtp.pending]: state => {
      state.loading = true
      state.error = null
      state.otpSent = false
    },
    [sentOtp.fulfilled]: (state) => {
      state.loading = false
      state.otpSent = true
      state.error = null
    },
    [sentOtp.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.otpSent = false
    },
    [verifyOtp.pending]: state => {
      state.loading = true
      state.error = null
      state.otpVerified = false
    },
    [verifyOtp.fulfilled]: (state) => {
      state.loading = false
      state.otpVerified = true
      state.error = null
    },
    [verifyOtp.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.otpVerified = false
    }
  }
})

export default authSlice.reducer
